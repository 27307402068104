import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import LogOutIcon from "@mui/icons-material/LogoutRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Settings from "@mui/icons-material/Settings";
import useAuth from "../../hooks/useAuth";
import { ChangeSize } from "../../slices/sidebar";
import MenuIcon from "../../assets/icons/hamburger-menu.svg";
import Logo from "../../assets/icons/logo.svg";
import User from "../../assets/images/user.jpg";
import NotificationsBox from "../../components/NotificationsBox";
import { handleSetUnreadNotifications } from "../../slices/notification";
import { getMe } from "../../slices/auth";
import { io } from "socket.io-client";
const socket = io(process.env.REACT_APP_SOCKET_API_URL);

const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    user && socket.emit("add_user", user?._id);
  }, [user]);
  useEffect(() => {
    socket.on("get_notifications", (data) => {
      dispatch(handleSetUnreadNotifications({ count: data }));
    });
  }, [socket, dispatch]);
  useEffect(() => {
    socket.on("check_notifications", () => {
      !user && dispatch(getMe());
      user && socket.emit("initial_notifications", user?._id);
    });
  }, [socket, user]);

  socket.emit("initial_notifications", user?._id);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    try {
      await logout();
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="header">
      <div className="headerleft">
        <div
          className="menu btn btn-submit"
          onClick={() => dispatch(ChangeSize())}
        >
          <img src={MenuIcon} alt="" />
        </div>
        <a href="/">
          <div className="logo">
            <img src={Logo} alt="" />
            <div className="logotext">TakiAcademy</div>
          </div>
        </a>
      </div>

      <div className="headerright">
        <NotificationsBox />

        <div
          className="user"
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <img className="userpic" src={user?.avatar || User} alt="" />
          <div className="usertext">
            <div className="title">{user?.name}</div>
            <div className="subtitle">{user?.email}</div>
          </div>
        </div>
        <Menu
          className="menui-header"
          anchorEl={anchorEl}
          autoFocus={false}
          id="account-menu"
          open={open}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              color: "#727272",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              position: "absolute",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 125,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <Link href={"/my-account"} className="link">
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              <span>My account</span>
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
              <LogOutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
