import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Slide } from "@mui/material";
import axios from "../utilities/axios";
import { enqueueSnackbar } from "./notifier";
import { closeUpdatePopUp } from "./popup";

const initialState = {
  user: null,
  status: "idle",
  error: null,

  updatePasswordStatus: "idle",

  updateUserPasswordStatus: "idle",

  email: "",

  verificationStepOne: true,
  verificationStepTwo: false,
  verificationStepThree: false,

  forgetStatus: "idle",
  forgetError: null,

  verifyStatus: "idle",
  verifyError: null,

  resetStatus: "idle",
  resettError: null,
};

export const getMe = createAsyncThunk("/getMe", async () => {
  let data;
  try {
    const response = await axios.get(`/me`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const updatePassword = createAsyncThunk(
  "/updatePassword",
  async ({ Data }, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/updatePassword`, Data);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "/updatePassword/user",
  async ({ userId, Data }, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(
        `/users/updatePassword/${userId}`,
        Data
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeUpdatePopUp());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "/forgetPassword",
  async ({ email }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/forgetPassword?email=${email}`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return { data, email };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const verifyCode = createAsyncThunk(
  "/verifyTokenPassword",
  async ({ Code, email }, thunkAPI) => {
    let data;
    let code = Number(Code);
    try {
      const response = await axios.post(`/verifyTokenPassword?email=${email}`, {
        code,
      });
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/resetPassword",
  async ({ password, passwordConfirm, email }, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/resetPassword?email=${email}`, {
        password,
        passwordConfirm,
      });
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleSetEmail: (state, action) => {
      state.email = action.payload.email;
    },
  },
  extraReducers: {
    [getMe.pending]: (state) => {
      state.status = "loading";
    },
    [getMe.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload.data;
    },
    [getMe.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [updatePassword.pending]: (state) => {
      state.updatePasswordStatus = "loading";
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.updatePasswordStatus = "succeeded";
    },
    [updatePassword.rejected]: (state, action) => {
      state.updatePasswordStatus = "failed";
      state.updatePasswordError = action.payload;
    },

    [updateUserPassword.pending]: (state) => {
      state.updateUserPasswordStatus = "loading";
    },
    [updateUserPassword.fulfilled]: (state, action) => {
      state.updateUserPasswordStatus = "succeeded";
    },
    [updateUserPassword.rejected]: (state, action) => {
      state.updateUserPasswordStatus = "failed";
    },

    [forgetPassword.pending]: (state) => {
      state.forgetStatus = "loading";
    },
    [forgetPassword.fulfilled]: (state, action) => {
      state.forgetStatus = "succeeded";
      if (action.payload?.data?.status) {
        state.email = action.payload.email;
        state.verificationStepOne = false;
        state.verificationStepTwo = true;
      }
    },
    [forgetPassword.rejected]: (state, action) => {
      state.forgetStatus = "failed";
      state.forgetError = action.payload;
    },

    [verifyCode.pending]: (state) => {
      state.verifyStatus = "loading";
    },
    [verifyCode.fulfilled]: (state, action) => {
      state.verifyStatus = "succeeded";
      if (action.payload?.status) {
        state.verificationStepTwo = false;
        state.verificationStepThree = true;
      }
    },
    [verifyCode.rejected]: (state, action) => {
      state.verifyStatus = "failed";
      state.verifyError = action.payload;
    },

    [resetPassword.pending]: (state) => {
      state.resetStatus = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.resetStatus = "succeeded";
      if (action.payload.status) {
        window.location = "/login";
      }
    },
    [resetPassword.rejected]: (state, action) => {
      state.resetStatus = "failed";
      state.resettError = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export default slice;
