import Logo from "../../assets/images/avatar.png";

const LoadingScreen = ({ style }) => {
  return (
    <div className="loading-screen" style={style}>
      <img alt="logo" src={Logo} />
    </div>
  );
};

export default LoadingScreen;
