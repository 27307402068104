import React from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import "./App.scss";
import routes, { renderRoutes } from "./routes";
import Notifier from "./layouts/notif";
import { AuthProvider } from "./contexts/JWTAuthContext";

function App() {
  return (
    <div>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={12}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Notifier />
        <BrowserRouter>
          <AuthProvider>{renderRoutes(routes)}</AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

export default App;
