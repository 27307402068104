import React from "react";
import { Suspense, Fragment, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./layouts/MainLayout";
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  <Component />
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./pages/Login")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/forget-password",
    component: lazy(() => import("./pages/ForgetPassword")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Home/index")),
  },
  {
    path: "/my-reservations",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/MyReservations/index")),
  },
  {
    path: "/reservations",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Reservations/index")),
  },
  {
    path: "/my-account",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/MyAccount/index")),
  },
  {
    path: "/rooms",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Rooms/index")),
  },
  {
    path: "/users",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/Users/index")),
  },
  {
    path: "*",
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import("./pages/NotFound/index")),
  },
];

export default routes;
