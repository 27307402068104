import { Slide } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { getMe } from "./auth";
import { enqueueSnackbar } from "./notifier";
import {
  closeConfirmDelete,
  closeCreatePopUp,
  closeUpdatePopUp,
} from "./popup";

const initialState = {
  userId: "",
  page: 0,
  rowsPerPage: 10,

  createUserStatus: "idle",
  updateUserStatus: "idle",
  deleteUserStatus: "idle",
  deleteAvatarStatus: "idle",
  deleteMeStatus: "idle",
  updateMeStatus: "idle",

  meta: [],
  user: null,
  userStatus: "idle",
  userError: null,

  allUsers: [],
  allUsersStatus: "idle",
  allUsersError: null,
};

export const getAllUsers = createAsyncThunk(
  "/users/getAllUsers",
  async ({ page, rowsPerPage, pagination, search="" }) => {
    let data;
    try {
      const response = await axios.get(
        `/users/all?pagination=${pagination}&page=${
          page + 1
        }&limit=${rowsPerPage}&search=${search}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getUser = createAsyncThunk("/users/getUser", async (userId) => {
  let data;
  try {
    const response = await axios.get(`/users/${userId}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const updateMe = createAsyncThunk(
  "/users/updateMe",
  async (Data, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/users/updateMe`, Data);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getMe());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  "/users/updateUser",
  async ({ userId, Data, page, rowsPerPage }, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/users/update/${userId}`, Data);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeUpdatePopUp());
        thunkAPI.dispatch(getAllUsers({ page, rowsPerPage }));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const deleteMe = createAsyncThunk(
  "/users/deleteMe",
  async (Data, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/users/deleteMe`, { data: Data });
      data = await response.data;
      if (response.status === 200) {
        localStorage.removeItem("token");
        thunkAPI.dispatch(closeConfirmDelete());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        window.location = "/login";
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(closeConfirmDelete());
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const createUser = createAsyncThunk(
  "/users/createUser",
  async ({ Data, page, rowsPerPage }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/users/create`, Data);
      data = await response.data;
      if (response.status === 201) {
        thunkAPI.dispatch(closeCreatePopUp());
        thunkAPI.dispatch(getAllUsers({ page, rowsPerPage }));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const deleteUser = createAsyncThunk(
  "/users/deleteUser",
  async ({ userId, page, rowsPerPage }, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/users/delete/${userId}`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeConfirmDelete());
        thunkAPI.dispatch(getAllUsers({ page, rowsPerPage }));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const deleteAvatar = createAsyncThunk(
  "/users/avatar",
  async (Data, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/users/avatar`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeConfirmDelete());
        thunkAPI.dispatch(getMe());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleSelectedUser: (state, action) => {
      state.userId = action.payload.userId;
    },
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.allUsersStatus = "loading";
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.allUsersStatus = "succeeded";
      state.allUsers = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.allUsersStatus = "failed";
      state.allUsersError = action.payload;
    },

    [getUser.pending]: (state) => {
      state.userStatus = "loading";
    },
    [getUser.fulfilled]: (state, action) => {
      state.userStatus = "succeeded";
      state.user = action.payload.data;
    },
    [getUser.rejected]: (state, action) => {
      state.userStatus = "failed";
      state.userError = action.payload;
    },

    [createUser.pending]: (state) => {
      state.createUserStatus = "loading";
    },
    [createUser.fulfilled]: (state) => {
      state.createUserStatus = "succeeded";
    },
    [createUser.rejected]: (state) => {
      state.createUserStatus = "failed";
    },

    [updateUser.pending]: (state) => {
      state.updateUserStatus = "loading";
    },
    [updateUser.fulfilled]: (state) => {
      state.updateUserStatus = "succeeded";
    },
    [updateUser.rejected]: (state) => {
      state.updateUserStatus = "failed";
    },

    [deleteUser.pending]: (state) => {
      state.deleteUserStatus = "loading";
    },
    [deleteUser.fulfilled]: (state) => {
      state.deleteUserStatus = "succeeded";
    },
    [deleteUser.rejected]: (state) => {
      state.deleteUserStatus = "failed";
    },

    [deleteMe.pending]: (state) => {
      state.deleteMeStatus = "loading";
    },
    [deleteMe.fulfilled]: (state) => {
      state.deleteMeStatus = "succeeded";
    },
    [deleteMe.rejected]: (state) => {
      state.deleteMeStatus = "failed";
    },

    [deleteAvatar.pending]: (state) => {
      state.deleteAvatarStatus = "loading";
    },
    [deleteAvatar.fulfilled]: (state) => {
      state.deleteAvatarStatus = "succeeded";
    },
    [deleteAvatar.rejected]: (state) => {
      state.deleteAvatarStatus = "failed";
    },

    [updateMe.pending]: (state) => {
      state.updateMeStatus = "loading";
    },
    [updateMe.fulfilled]: (state) => {
      state.updateMeStatus = "succeeded";
    },
    [updateMe.rejected]: (state) => {
      state.updateMeStatus = "failed";
    },
  },
});

export const { handleSelectedUser } = slice.actions;
export const reducer = slice.reducer;
export default slice;
