import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ReservationsIcon from "@mui/icons-material/Assignment";
import MyReservationsIcon from "@mui/icons-material/AssignmentInd";
import HomeIcon from "@mui/icons-material/Home";

const Sidebar = () => {
  const { open } = useSelector((state) => state?.sidebar);
  const { user } = useSelector((state) => state?.auth);

  const location = useLocation();

  return (
    <div className={open ? "sidebar" : "smallsidebar"}>
      <div className="sidebar-btns-container">
        <div
          className={
            ["/", "/CreateReservation"].includes(location.pathname)
              ? "sidebar-btn-container selected-btn"
              : "sidebar-btn-container unselected-btn"
          }
        >
          <NavLink to="/">
            <div className="sidebar-btn">
              <HomeIcon />
              <span>Home</span>
            </div>
          </NavLink>
        </div>
        <div
          className={
            location.pathname === "/my-reservations"
              ? "sidebar-btn-container selected-btn"
              : "sidebar-btn-container unselected-btn"
          }
        >
          <NavLink to="/my-reservations">
            <div className="sidebar-btn">
              <MyReservationsIcon />
              <span>My Reservations</span>
            </div>
          </NavLink>
        </div>
        {user?.role === "admin" && (
          <div
            className={
              location.pathname === "/reservations"
                ? "sidebar-btn-container selected-btn"
                : "sidebar-btn-container unselected-btn"
            }
          >
            <NavLink to="/reservations">
              <div className="sidebar-btn">
                <ReservationsIcon />
                <span>Resevations</span>
              </div>
            </NavLink>
          </div>
        )}

        {user?.role === "admin" && (
          <div
            className={
              location.pathname === "/rooms"
                ? "sidebar-btn-container selected-btn"
                : "sidebar-btn-container unselected-btn"
            }
          >
            {
              <NavLink to="/rooms">
                <div className="sidebar-btn">
                  <MeetingRoomIcon />
                  <span>Rooms</span>
                </div>
              </NavLink>
            }
          </div>
        )}
        {user?.role === "admin" && (
          <div
            className={
              location.pathname === "/users"
                ? "sidebar-btn-container selected-btn"
                : "sidebar-btn-container unselected-btn"
            }
          >
            {
              <NavLink to="/users">
                <div className="sidebar-btn">
                  <GroupIcon />
                  <span>Users</span>
                </div>
              </NavLink>
            }
          </div>
        )}
        <div
          className={
            location.pathname === "/my-account"
              ? "sidebar-btn-container selected-btn"
              : "sidebar-btn-container unselected-btn"
          }
        >
          {
            <NavLink to="/my-account">
              <div className="sidebar-btn">
                <PersonIcon />
                <span>My Account</span>
              </div>
            </NavLink>
          }
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
