import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: true,
  status: "idle",
  error: null,
};

const slice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    ChangeSize: (state) => {
      state.open = !state.open;
    },
  },
  extraReducers: {},
});

export const { ChangeSize } = slice.actions;
export const reducer = slice.reducer;
export default slice;
