import React from "react";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Header from "./Header";

const MainLayout = ({ children }) => {
  const { open } = useSelector((state) => state?.sidebar);
  const { popUp } = useSelector((state) => state?.popup);
  return (
    <div className="main-layout">
      <Header />
      <div className="page-body">
        <Sidebar />
        <div
          className={`${
            open ? "body-conatiner-margin-one" : "body-conatiner-margin-two"
          } body-container`}
          style={{
            overflow: popUp && "hidden",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
