import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  meta: [],
  notifications: [],
  notificationsNotSeen: [],
  unreadNotificationsCount: 0,

  notificationsStatus: "idle",
  notificationsError: null,
};

export const getNotifications = createAsyncThunk(
  "/notifications/getNotifications",
  async ({ page, limit = 10, seen, pagination = false }) => {
    let data;
    try {
      const response = await axios.get(
        `/notifications?page=${page}&limit=${limit}&seen=${seen}&pagination=${pagination}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        return { data, seen };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const setNotificationSeen = createAsyncThunk(
  "notification/seen",
  async ({ notifId }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/notifications/seen/${notifId}`);
      if (response.status === 200) {
        thunkAPI.dispatch(getNotifications({ seen: false }));
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    handleSetUnreadNotifications: (state, action) => {
      state.unreadNotificationsCount = action.payload.count;
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.notificationsStatus = "loading";
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.notificationsStatus = "succeeded";
      if (action.payload.seen) {
        state.notifications = action.payload.data;
      } else {
        state.notificationsNotSeen = action.payload.data;
      }
      state.meta = action.payload.meta;
    },
    [getNotifications.rejected]: (state, action) => {
      state.notificationsStatus = "failed";
      state.notificationsError = action.payload;
    },
  },
});

export const { handleSetUnreadNotifications } = slice.actions;
export const reducer = slice.reducer;
export default slice;
