import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import NotificationsIcon from "@mui/icons-material/NotificationsRounded";
import BasicTabs from "../TabPanel";
import { getNotifications } from "../../slices/notification";

export default function Notifications() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {
    notifications,
    notificationsNotSeen,
    notificationsStatus,
    unreadNotificationsCount,
  } = useSelector((state) => state?.notification);
  const [values, setValues] = useState({
    tab: 0,
    seen: false,
  });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    !open && dispatch(getNotifications({ seen: values.seen }));
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = (event, newValue) => {
    setValues({
      ...values,
      tab: newValue,
      seen: !values.seen,
    });
  };

  return (
    <div className="notifications-container" title="Notifications">
      <Stack direction="row" spacing={2}>
        <NotificationsIcon
          ref={anchorRef}
          onClick={handleToggle}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-haspopup="true"
        />
        {unreadNotificationsCount > 0 && (
          <div className="notif-count">
            <span>
              {unreadNotificationsCount > 99 ? "+99" : unreadNotificationsCount}
            </span>
          </div>
        )}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{ transform: "translate(50%, 44px)" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} className="notif-box ">
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <BasicTabs
                      children={
                        values.seen ? notifications : notificationsNotSeen
                      }
                      handleChange={handleChange}
                      handleListKeyDown={handleListKeyDown}
                      value={values.tab}
                      open={open}
                      status={notificationsStatus}
                    />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>
    </div>
  );
}
