import { Slide } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { enqueueSnackbar } from "./notifier";
import {
  closeConfirmDelete,
  closeCreatePopUp,
  closeUpdatePopUp,
} from "./popup";

const initialState = {
  page: 0,
  rowsPerPage: 10,

  createRoomStatus: "idle",
  deleteRoomStatus: "idle",
  updateRoomStatus: "idle",

  meta: [],
  allRooms: [],
  allRoomsStatus: "idle",
  allRoomsError: null,

  roomId: "all",
  roomStatus: "idle",
  roomError: null,

  roomIdSelected: "all",
  roomSelectedStatus: "idle",
  roomSelectedError: null,

  room: {},
  roomSelected: {},
};

export const getAllRooms = createAsyncThunk(
  "rooms/getAllRooms",
  async ({ page, rowsPerPage, pagination, sortBy, sortUp }) => {
    let data;
    try {
      const response = await axios.get(
        `/rooms/all?pagination=${pagination}&page=${
          page + 1
        }&limit=${rowsPerPage}&sortBy=${sortBy}&sortUp=${sortUp}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getRoom = createAsyncThunk("/rooms/getRoom", async (roomId) => {
  let data;
  try {
    const response = await axios.get(`/rooms/${roomId}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getSelectedRoom = createAsyncThunk(
  "/rooms/getRoomSelected",
  async (roomId) => {
    let data;
    try {
      const response = await axios.get(`/rooms/${roomId}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createRoom = createAsyncThunk(
  "/rooms/createRoom",
  async ({ Data, page, rowsPerPage }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/rooms/create`, Data);
      data = await response.data;
      if (response.status === 201) {
        thunkAPI.dispatch(closeCreatePopUp());
        thunkAPI.dispatch(getAllRooms({ page, rowsPerPage }));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const updateRoom = createAsyncThunk(
  "/rooms/createRoom",
  async ({ Data, roomId, page, rowsPerPage }, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/rooms/${roomId}`, Data);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeUpdatePopUp());
        thunkAPI.dispatch(getAllRooms({ page, rowsPerPage }));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const deleteRoom = createAsyncThunk(
  "/rooms/deleteRoom",
  async ({ roomId, page, rowsPerPage }, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`/rooms/${roomId}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(closeConfirmDelete());
        thunkAPI.dispatch(getAllRooms({ page, rowsPerPage }));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

const slice = createSlice({
  name: "room",
  initialState,
  reducers: {
    handleSelectedRoom: (state, action) => {
      state.roomIdSelected = action.payload.roomId;
    },
    handleRoom: (state, action) => {
      state.roomId = action.payload.roomId;
    },
  },
  extraReducers: {
    [getAllRooms.pending]: (state) => {
      state.allRoomsStatus = "loading";
    },
    [getAllRooms.fulfilled]: (state, action) => {
      state.allRoomsStatus = "succeeded";
      state.allRooms = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getAllRooms.rejected]: (state, action) => {
      state.allRoomsStatus = "failed";
      state.allRoomsError = action.payload;
    },

    [getRoom.pending]: (state) => {
      state.roomStatus = "loading";
    },
    [getRoom.fulfilled]: (state, action) => {
      state.roomStatus = "succeeded";
      state.room = action.payload.data;
    },
    [getRoom.rejected]: (state, action) => {
      state.roomStatus = "failed";
      state.roomError = action.payload;
    },

    [getSelectedRoom.pending]: (state) => {
      state.roomSelectedStatus = "loading";
    },
    [getSelectedRoom.fulfilled]: (state, action) => {
      state.roomSelectedStatus = "succeeded";
      state.roomSelected = action.payload.data;
    },
    [getSelectedRoom.rejected]: (state, action) => {
      state.roomSelectedStatus = "failed";
      state.roomSelectedError = action.payload;
    },

    [createRoom.pending]: (state) => {
      state.createRoomStatus = "loading";
    },
    [createRoom.fulfilled]: (state) => {
      state.createRoomStatus = "succeeded";
    },
    [createRoom.rejected]: (state) => {
      state.createRoomStatus = "failed";
    },

    [updateRoom.pending]: (state) => {
      state.updateRoomStatus = "loading";
    },
    [updateRoom.fulfilled]: (state) => {
      state.updateRoomStatus = "succeeded";
    },
    [updateRoom.rejected]: (state) => {
      state.updateRoomStatus = "failed";
    },

    [deleteRoom.pending]: (state) => {
      state.deleteRoomStatus = "loading";
    },
    [deleteRoom.fulfilled]: (state) => {
      state.deleteRoomStatus = "succeeded";
    },
    [deleteRoom.rejected]: (state) => {
      state.deleteRoomStatus = "failed";
    },
  },
});

export const { handleSelectedRoom, handleRoom } = slice.actions;
export const reducer = slice.reducer;
export default slice;
