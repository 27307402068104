import { combineReducers } from "@reduxjs/toolkit";
import { reducer as reservationReducer } from "../slices/reservation";
import { reducer as roomReducer } from "../slices/room";
import { reducer as sidebarReducer } from "../slices/sidebar";
import { reducer as userReducer } from "../slices/user";
import { reducer as authReducer } from "../slices/auth";
import { reducer as notifierReducer } from "../slices/notifier";
import { reducer as popupReducer } from "../slices/popup";
import { reducer as notificationReducer } from "../slices/notification";
import { reducer as messageReducer } from "../slices/message";

const combinedReducer = combineReducers({
  message: messageReducer,
  notification: notificationReducer,
  reservation: reservationReducer,
  room: roomReducer,
  sidebar: sidebarReducer,
  user: userReducer,
  auth: authReducer,
  notifier: notifierReducer,
  popup: popupReducer
});
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;
