import * as React from "react";
import { useDispatch } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import OneNotification from "../OneNotification";
import {
  getNotifications,
  setNotificationSeen,
} from "../../slices/notification";
import LoadingScreen from "../LoadingScreen";

function TabPanel(props) {
  const { children, value, index, handleChange, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function BasicTabs({
  children,
  value,
  handleChange,
  handleListKeyDown,
  open,
  status,
}) {
  const dispatch = useDispatch();

  const handleSeenNotification = (notifId) => {
    dispatch(setNotificationSeen({ notifId }));
  };
  return (
    <div className="tab_panel_wrapper">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={"not seen"}
              {...a11yProps(value)}
              onClick={() => dispatch(getNotifications({ seen: false }))}
            />
            <Tab
              label={"seen"}
              {...a11yProps(value)}
              onClick={() => dispatch(getNotifications({ seen: true }))}
            />
          </Tabs>
        </Box>
        <Box value={value} index={value} className="notif-scroll">
          {status === "loading" ? (
            <LoadingScreen />
          ) : (
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
              className="notif-scroll"
            >
              {children.length > 0 ? (
                children?.map((notif, i) => (
                  <div key={i}>
                    <MenuItem className={value === 1 ? "" : "not-seen"}>
                      <OneNotification
                        value={value}
                        children={notif}
                        handleSeenNotification={handleSeenNotification}
                      />
                    </MenuItem>
                    <Divider style={{ margin: "0" }} />
                  </div>
                ))
              ) : (
                <MenuItem>No Notifications</MenuItem>
              )}
            </MenuList>
          )}
        </Box>
      </Box>
    </div>
  );
}
