import { Slide } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { enqueueSnackbar } from "./notifier";

const initialState = {
  message: {},
  messages: [],
  meta: [],
  messagesStatus: "idle",
  messagesError: null,
};

export const getMessages = createAsyncThunk(
  "/messages/get",
  async ({ page, limit, reservationId, pagination = false }) => {
    let data;
    try {
      const response = await axios.get(
        `/messages/reservation/${reservationId}?page=${page}&limit=${limit}&pagination=${pagination}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createMessage = createAsyncThunk(
  "messages/create",
  async ({ reservationId, Data }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/messages/reservation/${reservationId}`,
        Data
      );
      data = await response.data.data.createdMessage;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

const slice = createSlice({
  name: "message",
  initialState,
  reducers: {
    handleAddMessage: (state, action) => {
      let found = false;
      for (let i = 0; i < state.messages.length; i++) {
        if (
          state.messages[i]._id.toString() ===
          action.payload.message._id.toString()
        ) {
          found = true;
          break;
        }
      }
      !found && state.messages.unshift(action.payload.message);
    },
  },
  extraReducers: {
    [getMessages.pending]: (state) => {
      state.messagesStatus = "loading";
    },
    [getMessages.fulfilled]: (state, action) => {
      state.messagesStatus = "succeeded";
      state.messages = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getMessages.rejected]: (state, action) => {
      state.messagesStatus = "failed";
      state.messagesError = action.payload;
    },
  },
});

export const { handleAddMessage } = slice.actions;
export const reducer = slice.reducer;
export default slice;
