import React from "react";
import dayjs from "dayjs";

function OneNotification({ value, children, handleSeenNotification }) {
  const handleClick = () => {
    value === 0 && handleSeenNotification(children._id);
  };
  return (
    <div className="one-notification" onClick={handleClick}>
      <div className="title">{children?.title}</div>
      <div className="body">{children?.body}</div>
      <div className="date">
        {dayjs(children?.createdAt).format("MMM D, YYYY h:mm A")}
      </div>
    </div>
  );
}

export default OneNotification;
