import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popUp: false,
  updatePopUp: false,
  createPopUp: false,
  showCardPopUp: false,
  confirmDelete: false,
  chatPopUp: false,
  itemId: "",
  status: "idle",
  error: null,
};

const slice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    openChatPopUp: (state) => {
      state.chatPopUp = true;
      state.popUp = true;
    },
    closeChatPopUp: (state) => {
      state.chatPopUp = false;
      state.popUp = false;
    },
    openUpdatePopUp: (state) => {
      state.updatePopUp = true;
      state.popUp = true;
    },
    closeUpdatePopUp: (state) => {
      state.updatePopUp = false;
      state.popUp = false;
    },
    openCreatePopUp: (state) => {
      state.createPopUp = true;
      state.popUp = true;
    },
    closeCreatePopUp: (state) => {
      state.createPopUp = false;
      state.popUp = false;
    },
    openShowCardPopUp: (state) => {
      state.showCardPopUp = true;
      state.popUp = true;
    },
    closeShowCardPopUp: (state) => {
      state.showCardPopUp = false;
      state.popUp = false;
    },
    openConfirmDelete: (state, action) => {
      state.confirmDelete = true;
      state.itemId = action.payload;
    },
    closeConfirmDelete: (state) => {
      state.confirmDelete = false;
      state.itemId = "";
    },
    closePopUp: (state) => {
      state.updatePopUp = false;
      state.createPopUp = false;
      state.showCardPopUp = false;
      state.chatPopUp = false;
      state.popUp = false;
    },
  },
  extraReducers: {},
});

export const {
  openUpdatePopUp,
  closeUpdatePopUp,
  openCreatePopUp,
  closeCreatePopUp,
  openShowCardPopUp,
  closeShowCardPopUp,
  openConfirmDelete,
  closeConfirmDelete,
  closePopUp,
  openChatPopUp,
  closeChatPopUp,
} = slice.actions;
export const reducer = slice.reducer;
export default slice;
