import { Slide } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { enqueueSnackbar } from "./notifier";
import {
  closeConfirmDelete,
  closeUpdatePopUp,
  closeCreatePopUp,
  closeShowCardPopUp,
} from "./popup";
import { handleSelectedRoom } from "./room";

const initialState = {
  reservationId: "",
  page: 0,
  rowsPerPage: 10,

  startView: 0,
  endView: 0,

  getByRoom: false,

  updateReservationStatus: "idle",
  createReservationStatus: "idle",
  deleteReservationStatus: "idle",

  showCardPopUp: false,

  meta: [],
  myReservations: [],
  myReservationsStatus: "idle",
  myReservationsError: null,

  reservationsByRoom: [],
  reservationsByRoomStatus: "idle",
  reservationsByRoomError: null,

  reservations: [],
  reservationsStatus: "idle",
  reservationsError: null,

  allReservations: [],
  allReservationsStatus: "idle",
  allReservationsError: null,

  reservation: {},
  reservationStatus: "idle",
  reservationError: null,
};

export const getReservationsByRoom = createAsyncThunk(
  "/reservations/getReservationsByRoom",
  async ({ roomId, startView, endView }) => {
    let data;
    try {
      const response = await axios.get(
        `/reservations/rooms/${roomId}?startView=${startView}&endView=${endView}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getReservations = createAsyncThunk(
  "/reservations/view",
  async ({ startView, endView }) => {
    let data;
    try {
      const response = await axios.get(
        `/reservations/view?startView=${startView}&endView=${endView}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getMyReservations = createAsyncThunk(
  "/reservations/getMyReservations",
  async ({
    page,
    rowsPerPage,
    roomId = "",
    startDate = "",
    endDate = "",
    search = "",
    userAs = "",
  }) => {
    let data;
    try {
      const response = await axios.get(
        `/reservations/all/me?page=${
          page + 1
        }&limit=${rowsPerPage}&roomId=${roomId}&search=${search}&startDate=${startDate}&endDate=${endDate}&userAs=${userAs}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAllReservations = createAsyncThunk(
  "/reservations/getAllReservations",
  async ({
    page,
    rowsPerPage,
    reservatorId = "",
    roomId = "",
    startDate = "",
    endDate = "",
    search = "",
  }) => {
    let data;
    try {
      const response = await axios.get(
        `/reservations/all?page=${
          page + 1
        }&limit=${rowsPerPage}&reservatorId=${reservatorId}&roomId=${roomId}&search=${search}&startDate=${startDate}&endDate=${endDate}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getReservation = createAsyncThunk(
  "/reservations/getReservation",
  async (body) => {
    const { reservationId } = body;
    let data;
    try {
      const response = await axios.get(`/reservations/${reservationId}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createReservation = createAsyncThunk(
  "reservations/room",
  async ({ roomId, Data, startView, endView, roomIdSelected }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/reservations/rooms/${roomId}`, Data);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeCreatePopUp());
        roomIdSelected === "all"
          ? thunkAPI.dispatch(getReservations({ startView, endView }))
          : thunkAPI.dispatch(
              handleSelectedRoom({ roomId }),
              getReservationsByRoom({ roomId, startView, endView })
            );
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const updateReservation = createAsyncThunk(
  "/reservations/updateReservation",
  async (
    {
      roomIdSelected,
      reservationId,
      Data,
      page,
      rowsPerPage,
      startView = initialState.startView,
      endView = initialState.endView,
      roomId,
    },
    thunkAPI
  ) => {
    let data;
    try {
      const response = await axios.patch(
        `/reservations/${reservationId}`,
        Data
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeUpdatePopUp());
        thunkAPI.dispatch(closeShowCardPopUp());
        thunkAPI.dispatch(getMyReservations({ page, rowsPerPage }));
        thunkAPI.dispatch(getAllReservations({ page, rowsPerPage }));
        thunkAPI.dispatch(
          roomIdSelected === "all"
            ? getReservations({ startView, endView })
            : getReservationsByRoom({ roomId, startView, endView })
        );
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

export const deleteReservation = createAsyncThunk(
  "/reservations/deleteReservation",
  async (
    {
      roomIdSelected,
      reservationId,
      page = initialState.page,
      rowsPerPage = initialState.rowsPerPage,
      startView = initialState.startView,
      endView = initialState.endView,
      roomId,
    },
    thunkAPI
  ) => {
    let data;
    try {
      const response = await axios.delete(`/reservations/${reservationId}`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(closeConfirmDelete());
        thunkAPI.dispatch(closeShowCardPopUp());
        thunkAPI.dispatch(
          roomIdSelected === "all"
            ? getReservations({ startView, endView })
            : getReservationsByRoom({ roomId, startView, endView })
        );
        thunkAPI.dispatch(getMyReservations({ page, rowsPerPage }));
        thunkAPI.dispatch(getAllReservations({ page, rowsPerPage }));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  }
);

const slice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    handleSelectedReservation: (state, action) => {
      state.reservationId = action.payload.reservationId;
    },
    handleGetReservations: (state, action) => {
      state.getByRoom = action.payload.getByRoom;
      state.page = action.payload.page;
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    handleSelectcalendarView: (state, action) => {
      state.startView = action.payload.startView;
      state.endView = action.payload.endView;
    },
  },
  extraReducers: {
    [getMyReservations.pending]: (state) => {
      state.myReservationsStatus = "loading";
    },
    [getMyReservations.fulfilled]: (state, action) => {
      state.myReservationsStatus = "succeeded";
      state.myReservations = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getMyReservations.rejected]: (state, action) => {
      state.myReservationsStatus = "failed";
      state.myReservationsError = action.payload;
    },

    [getReservationsByRoom.pending]: (state) => {
      state.reservationsByRoomStatus = "loading";
    },
    [getReservationsByRoom.fulfilled]: (state, action) => {
      state.reservationsByRoomStatus = "succeeded";
      state.reservationsByRoom = action.payload;
    },
    [getReservationsByRoom.rejected]: (state, action) => {
      state.reservationsByRoomStatus = "failed";
      state.reservationsByRoomError = action.payload;
    },

    [getReservations.pending]: (state) => {
      state.reservationsStatus = "loading";
    },
    [getReservations.fulfilled]: (state, action) => {
      state.reservationsStatus = "succeeded";
      state.reservations = action.payload;
    },
    [getReservations.rejected]: (state, action) => {
      state.reservationsStatus = "failed";
      state.reservationsError = action.payload;
    },

    [getAllReservations.pending]: (state) => {
      state.allReservationsStatus = "loading";
    },
    [getAllReservations.fulfilled]: (state, action) => {
      state.allReservationsStatus = "succeeded";
      state.allReservations = action.payload.data;
      state.meta = action.payload.meta;
    },
    [getAllReservations.rejected]: (state, action) => {
      state.allReservationsStatus = "failed";
      state.allReservationsError = action.payload;
    },

    [getReservation.pending]: (state) => {
      state.reservationStatus = "loading";
    },
    [getReservation.fulfilled]: (state, action) => {
      state.reservationStatus = "succeeded";
      state.reservation = action.payload.data;
    },
    [getReservation.rejected]: (state, action) => {
      state.reservationStatus = "failed";
      state.reservationError = action.payload;
    },

    [updateReservation.pending]: (state) => {
      state.updateReservationStatus = "loading";
    },
    [updateReservation.fulfilled]: (state) => {
      state.updateReservationStatus = "succeeded";
    },
    [updateReservation.rejected]: (state) => {
      state.updateReservationStatus = "failed";
    },

    [createReservation.pending]: (state) => {
      state.createReservationStatus = "loading";
    },
    [createReservation.fulfilled]: (state) => {
      state.createReservationStatus = "succeeded";
    },
    [createReservation.rejected]: (state) => {
      state.createReservationStatus = "failed";
    },

    [deleteReservation.pending]: (state) => {
      state.deleteReservationStatus = "loading";
    },
    [deleteReservation.fulfilled]: (state) => {
      state.deleteReservationStatus = "succeeded";
    },
    [deleteReservation.rejected]: (state) => {
      state.deleteReservationStatus = "failed";
    },
  },
});

export const {
  handleSelectedReservation,
  handleGetReservations,
  handleSelectcalendarView,
} = slice.actions;
export const reducer = slice.reducer;
export default slice;
